
import Vue from "vue";

export default Vue.extend({
  name: "E403",

  mounted() {
    console.log(this.$route);
  }
});
